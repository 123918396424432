<template>
  <div class="fj-block">
        <div v-if="item && item.id" class="fj-top">
            <div v-if="item.name">{{'单品'}}名称：{{item.name}}</div>
            <div v-if="item.cat && item.cat.name">类目：{{item.cat.name}}</div>
        </div>
        <div class="fj-main">
            <!-- <div class="tit">销售属性信息：</div> -->
            <!-- 编辑sku -->
            <EditSku v-if="catId" :catId="catId" :module_no="item.module_no" v-model="skus"  ref="editskus"></EditSku>
        </div>
    </div>
</template>

<script>
import { getSelfId } from '@/service/factory';
import EditSku from '@/components/sku/Edit.vue';
export default {
    components:{ EditSku },
    props: ['item'],
    data() {
        return {
            catId:null,
            skus:[],
            checkList: [],
            options: [{
                value: 3,
                label: "3个月"
            },{
                value: 6,
                label: "6个月"
            },{
                value: 12,
                label: "12个月"
            },{
                value: 18,
                label: "18个月"
            },{
                value: 24,
                label: "24个月"
            }],
            type:this.$route.query.type,
        }
    },
    created() {
        if (this.item && this.item.cat && this.item.cat.id) {
            this.catId = this.item.cat.id;
        }
        console.log(this.catId);
        if (this.type && this.type>=0) {
            this.skus=this.item.accessory[this.$route.query.type].step_price;
        } else {
            this.skus=this.item.step_price;
        }
    },
    methods:{
        // detail() {
        //     getSelfId(this.$route.params.id).then(rst=>{
        //         if (rst) {
        //             this.item=rst;
        //             this.skus=rst.step_price;
        //             this.catId = this.item.cat.id;
        //         }
        //         console.log(this.item);
        //     });
        // },
        // get_sku(){
        //     // console.log(this.skus);
        //     if (this.skus && JSON.stringify(this.skus) != "[]") {
        //         this.skus.forEach(e => {
        //             e.prices.forEach((n,idx1) => {
        //                 if (idx1===(e.prices.length-1)) {
        //                     n.end = null;
        //                 }
        //             })
        //         });
        //         return JSON.stringify(this.skus);
        //     } else {
        //         this.$message.error("请完善sku信息");
        //         return "";
        //     }
        // },
        goto(path) {
            this.$router.push(path);
        },
        async valid(){
            if (this.skus && this.skus.length>0) {
                await this.$refs.editskus.valid();
                return this.skus;
            } 
            else {
                this.$message.error("请完善sku信息");
                return "";
            }

        }
    }
}
</script>

<style scoped lang="less">
.fj-block {
    font-size: 14px;
    color: #808080;
    .fj-top {
        margin: 0 20px;
        padding: 20px;
        position: relative;
        .el-icon-delete {
            position: absolute;
            right: 110px;
            bottom: 11px;
            color: #016FFF;
            font-size: 25px;
            cursor: pointer;
        }
        >div {
            color: #4D4D4D;
            font-size: 16px;
            font-weight:600;
            line-height: 22px;
            margin-bottom: 8px;
            &:nth-child(2) {
                margin-bottom: 0;
            }
        }
        a {
            font-size: 14px;
            color: #5074EE;
            position: absolute;
            right: 20px; bottom: 20px;
        }
    }
    .fj-main {
        padding: 0 20px;
        .form-line {
            display: flex;
            align-items: center;
            margin-top: 16px;
            &.size-container {
                align-items: flex-start;
                >div {
                    width: 1036px;
                    background-color: #F5F5F5;
                    height: 130px;
                    padding: 16px 24px;
                    .el-checkbox {
                        margin-top: 5px;
                        color: #808080;
                    }
                }
            }
            >label {
                width: 100px;
                text-align: right;
                margin-right: 16px;
                flex-shrink: 0;
                span {
                    color: #D70000;
                    font-size: 20px;
                    position: relative;
                    top: 6px;
                    margin-right: 5px;
                }
            }
            >div {
                display: flex;
                .el-select {
                    width: 240px;
                }
                .el-input {
                    margin-left: 32px;
                }
            } 
        }
        p {
            margin-top: 26px;
            span {
                margin-left: 30px;
            }
        }
        .table-container {
            display: flex;
            align-items: flex-start;
            padding-left: 20px;
            span {
                color: #D70000;
                font-size: 20px;
                margin-right: 18px;
            }
            table {
                width: 1347px;
                th,
                td {
                    border: 1px solid #707070;
                    text-align: center;
                    height: 62px;
                }
            }
        }
    }
}
</style>